import React from 'react';
import {useRoutes} from 'hookrouter';
import MapView from './MapView';
import BlankPage from './BlankPage';

const routes = {
    '/i/:group': ({group}) => <MapView group={group} />
};
	
const App = () => {
    const routeResult = useRoutes(routes);
    
    return routeResult || <BlankPage/>;
}

export default App;