import React from 'react';
import Modal from './modal/index';
import useSignUpForm from './useSignUpForm';
import DateTime from 'react-datetime';
import 'moment/locale/en-ie';
import './Popup.css';

function Popup(props) {

    const {isOpen, close, lngLat} = props;

    const onAdd = () => {
        close({lngLat:lngLat, starts:inputs.starts, ends:inputs.ends, location:inputs.location, host:inputs.host, description:inputs.description});
        inputs.starts = "";
        inputs.ends ="";
        inputs.location = "";
        inputs.host = "";
        inputs.description = "";
    }
    const {inputs, handleInputChange, handleStartChange, handleEndChange, handleSubmit} = useSignUpForm(onAdd);

    const getNearTime = () => {
      var d = new Date();
      d.setMinutes (d.getMinutes() + 30);
      d.setMinutes (0,0,0);
      return d;
    }

    return (
        <div>
        {isOpen && (
          <Modal open={isOpen} close={() => {close(null);inputs.starts = "";inputs.ends="";inputs.location = "";inputs.description = "";inputs.host = "";}}>
            <h1>Invitation</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Starts</label>
                    <br/>
                    <DateTime locale="en-ie" input={true} timeFormat="HH:mm" timeConstraints={{minutes: {step:5}}} onChange={handleStartChange} value={inputs.starts || getNearTime()} />
                    <br/>
                    <label>Ends</label>
                    <br/>
                    <DateTime locale="en-ie" input={true} timeFormat="HH:mm" timeConstraints={{minutes: {step:5}}} onChange={handleEndChange} value={inputs.ends || getNearTime()} />
                    <br/>
                    <label>Location</label>
                    <br/>
                    <input type="text" name="location" onChange={handleInputChange} value={inputs.location || ''} required />
                    <br/>
                    <label>Host</label>
                    <br/>
                    <input type="text" name="host" onChange={handleInputChange} value={inputs.host || ''} required />
                    <br/>                    
                    <label>Description</label>
                    <br/>
                    <input type="text" name="description" onChange={handleInputChange} value={inputs.description || ''} required />
                    <br/>
                    <button type="submit">Add</button>
                </div>            
            </form>
          </Modal>
        )}
      </div>
    );
}

export default Popup;