import React, { useState, createRef, useEffect, useLayoutEffect } from 'react';
import mapboxgl from 'mapbox-gl';

import './Map.css';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = "pk.eyJ1IjoiaGF2YWluLW5ldCIsImEiOiJjanllN2I5bnQwem8xM2xwYmx5MGVmcXFmIn0.7YkNPdj9q1p1bHgmAqLT4g";

function addNewPoint(map, geojson, lngLat) {
  if (map.getSource('point') == null) {
    map.addSource('point', {
      "type": "geojson",
      "data": geojson
    });

    map.addLayer({
      "id": "point",
      "type": "circle",
      "source": "point",
      "paint": {
        "circle-radius": 6,
        "circle-color": "#ffffff",
        "circle-stroke-width": 2,
        "circle-stroke-color": "#d0451b"
      }
    });
  }

  geojson.features[0].geometry.coordinates = [lngLat.lng, lngLat.lat];
  map.getSource('point').setData(geojson);
}

function Map(props) {
  const mapContainer = createRef();

  const {layer, selection, itemPopup} = props;

  const [map, setMap] = useState(null);

  useEffect(() => {
    if (map && map.isStyleLoaded()) {
      if (map.getLayer("points"))
        map.removeLayer("points");

      if (map.getSource("points"))
        map.removeSource("points");

      map.addLayer(layer);
    }
  });

  useLayoutEffect(() => {
    console.log("Map.useLayoutEffect");

    const geojson = {
      "type": "FeatureCollection",
      "features": [{
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": []
        }
      }]
    };

    let mousePoint = { x: 0, y: 0 };

     const _map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v9',
      center: [24.94, 60.17], // note: lon comes before lat
      zoom: [13],
      attributionControl: false
    });
    setMap(_map);

    const _popup = new mapboxgl.Popup({closeOnClick: false});
    _popup.on('close', function(e) {
      selection({selected:"None", lngLat:{}, id: -1});
    });
    itemPopup(_popup);

    _map.on('load', () => {
      //loadPoints(map);
      console.log("Map layer", layer);
      _map.addLayer(layer);
      _map.getCanvas().style.cursor = 'pointer';
    });

    _map.on('mousedown', (e) => {
      mousePoint = e.point;
      if (_popup.isOpen())
        _popup.remove();

      _map.once('mouseup', onMouseUp);
    });

    _map.on('mousedown', 'points', (e) => {
      mousePoint = e.point;
      _map.off('mouseup', onMouseUp);
      _map.once('mouseup', onMouseUpPoints);
    });

    function onMouseUp(e) {
      if (Math.abs(e.point.x - mousePoint.x) < 4 &&
        Math.abs(e.point.y - mousePoint.y) < 4) {
          addNewPoint(_map, geojson, e.lngLat);
          selection({selected: "New", lngLat:e.lngLat, id: -1});
      }
    }

    function onMouseUpPoints(e) {
      console.log("onMouseUpPoints", e);
      var features = _map.queryRenderedFeatures(e.point);

      console.log("Map onMouseUpPoints features", features[0]);

      const coordinates = features[0].geometry.coordinates.slice();
      const description = features[0].properties.description;
      
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      if (_map.getLayer("point"))
        _map.removeLayer("point");

      if (_map.getSource("point"))
        _map.removeSource("point");

      selection({selected: "Item", lngLat:e.lngLat, id: features[0].properties.id});

      _popup.setLngLat(coordinates).setHTML(description).addTo(_map);
    }

  // eslint-disable-next-line   react-hooks/exhaustive-deps
  }, []);
//}, [mapContainer, openPopup, layer]);


  return (
    <div>
      <div style={{ height: "99vh" }}
        className="absolute top right left bottom"
        ref={mapContainer}></div>
    </div>
  );
}

export default Map;
