import React from 'react';
import './Button.css';

function Button(props) {

    const {children, callback, className} = props;

    return (
        <div className="button-add-container">
            <button className={className} onClick={callback}>{children}</button>
        </div>    
    );
}

export default Button