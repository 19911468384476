import React, { useState, useLayoutEffect } from 'react';
import Popup from './Popup';
import Map from './Map';
import Button from './Button';
import uuidv4 from 'uuid/v4';
import dateFormat from 'dateformat';

export const convertToMapbox = (value) => {

  console.log("MapView.convertToMapbox value", value);

  const features = value.map(function (s) {
    return {
      "type": "Feature",
      "id" : s.data.id,
      "geometry": {
        "type": "Point",
        "coordinates": [s.data.lng, s.data.lat]//coordinates
      },
      "properties": {
        "id" : s.ref["@ref"].id,
        "description": 
        "<strong>" + s.data.location + "</strong>" +
        "<p>" + s.data.host + ": " + s.data.description + "<br/>" +
        dateFormat(new Date(s.data.begin), "d.m.yyyy HH:MM") + " - " + dateFormat(new Date(s.data.end), "d.m.yyyy HH:MM") + "</p>"
      }
    };
  })

  const layer =
  {
    "id": "points",
    "type": "circle",
    "source": {
      "type": "geojson",
      "data": {
        "type": "FeatureCollection",
        "features": features
      }

    },
    "paint": {
      "circle-radius": 15,
      "circle-color": "#d0451b",
      "circle-stroke-width": 2,
      "circle-stroke-color": "#942911"
    }
  };

  return layer;
};

const readAll = (group) => {
  return fetch('/.netlify/functions/read-invitations-by-group/' + group).then((response) => {
    return response.json()
  })
}

const createInvitation = (values) => {
  return fetch('/.netlify/functions/create-invitation', {
    body: JSON.stringify(values),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}

const deleteInvitation = (id) => {
  return fetch(`/.netlify/functions/delete-invitation/${id}`, {
    method: 'POST',
  }).then(response => {
    return response.json()
  })
}

function MapView(props) {
  const [data, setData] = useState([]);
  const [layer, setLayer] = useState(convertToMapbox(data));
  const [reload, setReload] = useState(true);
  const {group} = props;

  console.log("MapView group", group);

  useLayoutEffect(() => {
    if (reload)
      readAll(group).then((invitations) => {
        console.log("MapView readAll", invitations);
        setData(invitations);
      });
      setReload(false);
  }, [reload, group])

  useLayoutEffect(() => {
    console.log("MapView useLayoutEffect layer");

    setLayer(convertToMapbox(data));
  },[data]);

  const [popupOpen, setPopupOpen] = useState(false);
  const [selection, setSelection] = useState({selected:"None", lnglat: {}, id:-1});
  const [itemPopup, setItemPopup] = useState(null);

  const closeCallback = (invitation) => {
      setPopupOpen(false);
      console.log("MapView invitation starts", invitation.starts);
      if (invitation) {        
        //const s =  {"id": 3, "coordinates":[newSitting.lngLat.lng, newSitting.lngLat.lat], "location": newSitting.location, "description":newSitting.description};
        const d = {
          "id" : uuidv4(),
          "group" : group,
          "lng" : invitation.lngLat.lng,
          "lat" : invitation.lngLat.lat,
          "begin" : invitation.starts.format(),
          "end" : invitation.ends.format(),
          "host" : invitation.host,
          "location" : invitation.location,
          "description" : invitation.description
        }
        createInvitation(d).then((response) => {
          console.log("MapView closeCallback create response", response);
          setReload(true);
        });
        //setData([...data, s]);
      }
      else
        console.log("empty");
  };

  const selectionCallback = (_selection) => {
    setSelection(_selection);
    console.log("MapView selectionCallback", _selection);
  }

  const openCallback = () => {
    setPopupOpen(true);
  };

  const removeCallback = () => {
    console.log("MapView removeCallback selection", selection.id);
    deleteInvitation(selection.id).then((response) => {
      setSelection({selected:"None", lngLat: {}, id: -1})
      if (itemPopup)
        itemPopup.remove()

      setReload(true);
    })
    //setData(data.filter((d) => d.data.id !== selection.id));
  }

  const reloadCallback = () => {
    setReload(true);
  }

  const itemPopupCallback = (_itemPopup)  => {
    setItemPopup(_itemPopup);
  }

  return (
    <div style={{ height: "99vh" }} className="absolute top right left bottom">
      <Popup isOpen={popupOpen} close={closeCallback} lngLat={selection.lngLat}/>
      { (data && data.length > 0) ? <Map layer={layer} selection={selectionCallback} itemPopup={itemPopupCallback}/> : null}
      <Button callback={reloadCallback} className={"button-reload"}>R</Button>
      {selection.selected === "New" ? <Button callback={openCallback} className={"button-add"}>+</Button> : null}
      {selection.selected === "Item" ? <Button callback={removeCallback} className={"button-remove"}>- </Button> : null}
    </div>
  );
}

export default MapView;
